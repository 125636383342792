import React, { Component, Suspense } from "react";
import {Route, Switch, HashRouter} from "react-router-dom";
import {Helmet} from "react-helmet";

// Importing Section
const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));

const Section = React.lazy(() => import("../Dconex/Section"));
const About = React.lazy(() => import("../../component/About"));
const Jobs = React.lazy(() => import("../../component/Jobs"));
const Features = React.lazy(() => import("../../component/Features"));
// const Clients = React.lazy(() => import("../../component/Clients"));
const Projects = React.lazy(() => import("../../component/Projects"));
const Contact = React.lazy(() => import("../../component/Contact"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));

//const Pricing = React.lazy(() => import("../../component/Pricing"));

const meta = {
  title: "DCONEX - Making Connections Delivering Results",
  description: "Our team has helped over 30+ companies – from ambitious startups, global nonprofits to large enterprises – build and scale their products across all stages of the product lifecycle.",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "Startup, Digital, Connections, Delivering, Database Architecture, Front-end, Back-end, React, QA, Testing"
    }
  }
};
//author: "DCONEX",
//viewport: "width=device-width, initial-scale=1",

class Dconex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "/#home", navheading: "Home" },
        { id: 2, idnm: "/#about", navheading: "About" },
        //{ id: 3, idnm: "#services", navheading: "Services" },
        { id: 3, idnm: "/#features", navheading: "Features" },
        //{ id: 5, idnm: "#pricing", navheading: "Pricing" },
        { id: 4, idnm: "/#jobs", navheading: "Jobs" },
        { id: 5, idnm: "/#testimonials", navheading: "Testimonials" },
        { id: 6, idnm: "/#projects", navheading: "Projects" },
        { id: 7, idnm: "/#contact", navheading: "Contact Us" },
      ],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "navbar-light navbar-custom sticky sticky-dark",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky navbar-light navbar-custom sticky sticky-dark", imglight: false });
    } else {
      this.setState({ navClass: "navbar-light navbar-custom sticky sticky-dark", imglight: true });
    }
  };

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {

    return (
        <HashRouter>
      <React.Fragment>

        <Helmet>
          <meta name="robots" content="noindex" />
          <link rel="canonical" href={window.location.href} />
        </Helmet>

        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />
          <Section />

            <Switch>
              <Route  path="/jobs/:key" exact>
                <Jobs />
              </Route>

              <Route  path="/projects/:key" exact>
                <Projects />
              </Route>

              <Route path="/home">
                <Features />
              </Route>

              <Route path="/">


                <div id="about">
                  <About />
                </div>

                <div id="features" className="mobile-fix">
                  <Features />
                </div>

                {/*<Pricing />*/}

                <Jobs />

                {/*<Clients />*/}

                <Projects />
              </Route>

            </Switch>


          <Contact />

          <Footer />

        </Suspense>
      </React.Fragment>
        </HashRouter>
    );
  }
}
export default Dconex;
