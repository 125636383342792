
import Layout1 from "./pages/Layout1/Dconex";
import Layout2 from './pages/Layout2/Layout2';
import Layout3 from './pages/Layout3/Layout3';
import Layout4 from './pages/Layout4/Layout4';
import Dconex from './pages/Dconex/Dconex';
import Layout6 from './pages/Layout6/Layout6';
import Layout7 from './pages/Layout7/Layout7';
import Layout8 from './pages/Layout8/Layout8';

const routes = [
  { path: "/layout2", component: Layout2 },
  { path: "/layout3", component: Layout3 },
  { path: "/layout4", component: Layout4 },
  { path: "/dconex", component: Dconex },
  { path: "/layout6", component: Layout6 },
  { path: "/layout7", component: Layout7 },
  { path: "/layout8", component: Layout8 },
  { path: "/", component: Dconex },
];

export default routes;
